
$ja-font: fot-cezanne-pron;
//$en-font: aktiv-grotesk;
$en-font:'Real Text W01 Book';

%ja-font{
  font-family: $ja-font, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

%en-font-base{
  font-family: $en-font, sans-serif;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

%en-font,
%en-font-regular{ @extend %en-font-base; font-weight: 400; }
%en-font-medium { @extend %en-font-base; font-weight: 500; }
%en-font-bold   { @extend %en-font-base; font-weight: 700; }
%en-font-xbold  { @extend %en-font-base; font-weight: 800; }

//sizeing
$map-min: 10px;
$map-max: 16px;
$map-base: (320px: $map-min, 1280px: $map-max);
$map-base-half: (320px: $map-min/2, 1280px: $map-max/2);
$map-base-onehalf: (320px: $map-min*1.5, 1280px: $map-max*1.5);
$map-base-double: (320px: $map-min*2, 1280px: $map-max*2);
$map-xsmall: (320px: 10px, 1280px: 12px);
$map-small: (320px: 12px, 1280px: 14px);
$map-large: (320px: 18px, 1280px: 20px);
$map-xlarge: (320px: 20px, 1280px: 22px);

$font-size: (
	xsmall: linear-interpolation($map-xsmall),
	small: linear-interpolation($map-small),
	base: linear-interpolation($map-base),
	base-half: linear-interpolation($map-base-half),
	base-onehalf: linear-interpolation($map-base-onehalf),
	base-double: linear-interpolation($map-base-double),
	large: linear-interpolation($map-large),
	xlarge: linear-interpolation($map-xlarge),
);

@function font-size($key) {
  @if map-has-key($font-size, $key) {
    @return map-get($font-size, $key);
  }
  @warn "Unkown '#{$key}' in $font-size.";
  @return null;
}

body{
  @extend %ja-font;
  margin: 0; padding: 0;
  font-size: font-size(base);
}

h1, h2, h3, h4, h5, h6{
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1em;
  line-height: 1;
  font-feature-settings: "pwid";
  &.en{
    @extend %en-font-medium;
    font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0;
  }
}

p{
  margin-top: 0;
  margin-bottom: font-size(base-half);
  font-size: font-size(small);
  line-height: font-size(base-onehalf);
  &.en{
    font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0;
  }
}

b, strong{
  .en &,
  &.en{
    @extend %en-font-bold;
    font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0;
  }
}
i{
  font-feature-settings: "ital";
}
