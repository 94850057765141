
.info-container{
  // @extend %em-grid; //グリッドテスト
  transition: bottom 0.4s ease(out-quint);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: fixed; z-index: 256;
  bottom: 100%;
  left: 0; right: 0;
  max-height: 100vh;
  background-color: $white;
  &.open{ bottom: 0; }
  @include breakpoint(medium up){
    padding: 0 2em;
    .arrow.bottom{
      position: absolute;
      left: calc(50% - 16px);
      bottom: 0;
    }
  };
  @include breakpoint(small down){
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 2em;
  };
  .about-post,
  .contact-post{
    overflow-y: auto;
    margin-top: 2em;
    margin-bottom: 3em;
    @include breakpoint(small down){
      width: 100%;
      margin-top: 2em;
      margin-bottom: 0;
    };
  }
  .arrow.bottom,
  .about-post{ @include breakpoint(small down){ order: 1; } }
  .contact-post{ @include breakpoint(small down){ order: 0; } }
}

.about-post{
  width: calc(#{100%/12*5} - 0.5em);
  .wp-block-columns{
    align-items: flex-end;
  }
  figure{
    height: 8em;
    margin-bottom: 1em;
  }
  img{
    width: 100%; height: 100%;
  }
  p{
    text-align: justify;
  }
}

.contact-post{
  width: calc(#{100%/24*11} - 0.5em);
}
