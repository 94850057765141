img{
  max-width: 100%;
  vertical-align: bottom;
}
.post-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5em;
}

@keyframes loading-loop {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.single-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  width: 100%; height: calc(100% - 3em);
  .post, img{ width: 100%; height: 100%; }
}

.post-container{
  .post{
    transition: height 1s ease(out-quint);
    width: 33.333%;
  }
  .post.loading{
    height: 22.692vw;
  }
  a{
    display: block;
    padding: 0.5em;
  }
}

.post{
  &{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $gray;
    &:after{
      transition: opacity 0.5s linear;
      overflow: hidden;
      opacity: 0; height: 0;
      content: '—';
    }
    &.loading:after{
      opacity: 1; height: auto;
      transform-origin: center;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-name: loading-loop;
    }
  }
  img{
    transition: opacity 1s linear;
    opacity: 0;
    &.show{ opacity: 1; }
  }
}
