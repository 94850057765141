*{
  box-sizing:border-box;
}

*{ //ios 子要素でも滑らかなスクロールを実現させる
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}


a,p{
  word-wrap: break-word;
}

main{
	display: block;
}

figure {
  margin: 0;
}

%ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
