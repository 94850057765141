.arrow{
  $size: 16px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  transform-origin: center;
  padding-top: $size;
  width: $size*2; height: 0;
  &:before, &:after{
    content: '';
    position: absolute;
    top: 2px;
    left: calc(50% - 0.5px);
    height: $size;
  }
  &:before{
    margin-left: calc(#{-$size/2} - 0.5px);
    transform-origin: center;
    transform: rotate(35deg) skewX(-20deg);
    border-top: 1px solid;
    border-left: 1px solid;
    width: $size; height: $size;
  }
  &:after{
    top: 3px;
    border-left: 1px solid;
  }
  &.bottom{ transform: rotate(180deg); }
  &.left{ transform: rotate(-90deg); }
  &.right{ transform: rotate(90deg); }
}
