img{
  overflow: hidden;
  vertical-align: bottom;
	object-fit: cover;
  font-family: 'object-fit: cover;'; //for IE,Edge poliyfill
  &.contain{
    object-fit: contain;
    font-family: 'object-fit: contain;'; //for IE,Edge poliyfill
  }
}
