.page-navigation{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 8em;
  .single &{
    position: fixed;
    left: 0; right: 0;
    bottom: 1em;
    height: 2em;
  }
  .empty{
    color: $gray;
  }
}
