// MW WP FORM Wordpressプラグインを使用
// <dl>
// <div><dt><label for="contact-name">お名前<label><dd>[mwform_text id="contact-name" name="お名前" size="60" placeholder=""]</dd></div>
// <div><dt><label for="contact-email">メールアドレス<label></dt><dd>[mwform_email id="contact-email" name="メールアドレス" size="60" placeholder=""]</dd></div>
// <div><dt><label for="contact-message">メッセージ<label></dt><dd>[mwform_textarea id="contact-message" name="メッセージ" cols="50" rows="5" placeholder=""]</dd></div>
// </dl>
// [mwform_submitButton name="contact-submit" confirm_value="確認画面へ" submit_value="送信する"]

form{

  &{
    display: block;
    margin-bottom: 1em;
  }

  p:empty{ display: none; }

  dl div{
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 1em;
    &:before{
      content: '';
      position: absolute; z-index: 1;
      left: 0; right: 0;
      bottom: 0;
      border-bottom: 1px solid;
    }
  }
  dt{
    flex: 0 0 auto;
    margin-right: 1em;
    white-space: nowrap;
    line-height: font-size(base-double);
    font-weight: bold;
    font-size: font-size(xsmall);
  }
  dd{
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    margin: 0;
  }
  textarea,
  input[type='text'],
  input[type='email']{
    margin: 0;
    padding: 0.25em 0.5em;
    width: 100%; min-height: 100%;
    border: none;
    line-height: 1.5;
    font-size: 1rem;
  }
  textarea{
    height: 10em;
    @include breakpoint(small down){
      height: 5em;
    };
  }
  p{
    text-align: right;
  }
  input[type='submit']{
    appearance: none;
    border-radius: 0.5em;
    padding: 0 2em;
    background-color: $white;
    border: 1px solid;
    height: font-size(base-double);
    line-height: font-size(base-double);
    font-size: font-size(xsmall);
  }

  .error{
    @extend %en-font-medium;
    display: block;
    text-align: right;
    color: $red;
  }

}
