.cover{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw; height: 100vh;
  .site-title{ order: 2; }
  .arrow.top{ order: 1; }
  .arrow.bottom{ order: 3; }
}

.site-title{
  @extend %en-font-medium;
  margin: 0;
  font-size: font-size(base-double);
}
