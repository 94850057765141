@charset "UTF-8";
* {
  box-sizing: border-box;
}

* {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

a, p {
  word-wrap: break-word;
}

main {
  display: block;
}

figure {
  margin: 0;
}

img {
  overflow: hidden;
  vertical-align: bottom;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
img.contain {
  object-fit: contain;
  font-family: "object-fit: contain;";
}

@keyframes blink-once {
  0%, 49%, 100% {
    opacity: 1;
  }
  50%, 99% {
    opacity: 0;
  }
}
a {
  color: #000;
}

body {
  font-family: fot-cezanne-pron, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

.en b, b.en, .en strong, strong.en, .page-post h1, .page-post .wp-block-column:last-of-type h2, .page-post .wp-block-column:last-of-type h3, form .error, .site-title, h1.en, h2.en, h3.en, h4.en, h5.en, h6.en, .page-post .wp-block-column:last-of-type {
  font-family: "Real Text W01 Book", sans-serif;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

.page-post .wp-block-column:last-of-type {
  font-weight: 400;
}

.page-post h1, .page-post .wp-block-column:last-of-type h2, .page-post .wp-block-column:last-of-type h3, form .error, .site-title, h1.en, h2.en, h3.en, h4.en, h5.en, h6.en {
  font-weight: 500;
}

.en b, b.en, .en strong, strong.en {
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  font-size: calc(0.625vw + 8px);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1em;
  line-height: 1;
  font-feature-settings: "pwid";
}
h1.en, h2.en, h3.en, h4.en, h5.en, h6.en {
  font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0;
}

p {
  margin-top: 0;
  margin-bottom: calc(0.3125vw + 4px);
  font-size: calc(0.2083333333vw + 11.3333333333px);
  line-height: calc(0.9375vw + 12px);
}
p.en {
  font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0;
}

.en b, b.en, .en strong, strong.en {
  font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0;
}

i {
  font-feature-settings: "ital";
}

html.single, body.single {
  height: 100%;
}

.arrow {
  overflow: hidden;
  position: relative;
  display: inline-block;
  transform-origin: center;
  padding-top: 16px;
  width: 32px;
  height: 0;
}
.arrow:before, .arrow:after {
  content: "";
  position: absolute;
  top: 2px;
  left: calc(50% - 0.5px);
  height: 16px;
}
.arrow:before {
  margin-left: calc(-8px - 0.5px);
  transform-origin: center;
  transform: rotate(35deg) skewX(-20deg);
  border-top: 1px solid;
  border-left: 1px solid;
  width: 16px;
  height: 16px;
}
.arrow:after {
  top: 3px;
  border-left: 1px solid;
}
.arrow.bottom {
  transform: rotate(180deg);
}
.arrow.left {
  transform: rotate(-90deg);
}
.arrow.right {
  transform: rotate(90deg);
}

.cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
}
.cover .site-title {
  order: 2;
}
.cover .arrow.top {
  order: 1;
}
.cover .arrow.bottom {
  order: 3;
}

.site-title {
  margin: 0;
  font-size: calc(1.25vw + 16px);
}

form {
  display: block;
  margin-bottom: 1em;
}
form p:empty {
  display: none;
}
form dl div {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 1em;
}
form dl div:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid;
}
form dt {
  flex: 0 0 auto;
  margin-right: 1em;
  white-space: nowrap;
  line-height: calc(1.25vw + 16px);
  font-weight: bold;
  font-size: calc(0.2083333333vw + 9.3333333333px);
}
form dd {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  margin: 0;
}
form textarea,
form input[type=text],
form input[type=email] {
  margin: 0;
  padding: 0.25em 0.5em;
  width: 100%;
  min-height: 100%;
  border: none;
  line-height: 1.5;
  font-size: 1rem;
}
form textarea {
  height: 10em;
}
@media screen and (max-width: 39.99875em) {
  form textarea {
    height: 5em;
  }
}
form p {
  text-align: right;
}
form input[type=submit] {
  appearance: none;
  border-radius: 0.5em;
  padding: 0 2em;
  background-color: #fff;
  border: 1px solid;
  height: calc(1.25vw + 16px);
  line-height: calc(1.25vw + 16px);
  font-size: calc(0.2083333333vw + 9.3333333333px);
}
form .error {
  display: block;
  text-align: right;
  color: #d60808;
}

.info-container {
  transition: bottom 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 256;
  bottom: 100%;
  left: 0;
  right: 0;
  max-height: 100vh;
  background-color: #fff;
}
.info-container.open {
  bottom: 0;
}
@media screen and (min-width: 40em) {
  .info-container {
    padding: 0 2em;
  }
  .info-container .arrow.bottom {
    position: absolute;
    left: calc(50% - 16px);
    bottom: 0;
  }
}
@media screen and (max-width: 39.99875em) {
  .info-container {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 2em;
  }
}
.info-container .about-post,
.info-container .contact-post {
  overflow-y: auto;
  margin-top: 2em;
  margin-bottom: 3em;
}
@media screen and (max-width: 39.99875em) {
  .info-container .about-post,
.info-container .contact-post {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 39.99875em) {
  .info-container .arrow.bottom,
.info-container .about-post {
    order: 1;
  }
}
@media screen and (max-width: 39.99875em) {
  .info-container .contact-post {
    order: 0;
  }
}

.about-post {
  width: calc(41.6666666667% - 0.5em);
}
.about-post .wp-block-columns {
  align-items: flex-end;
}
.about-post figure {
  height: 8em;
  margin-bottom: 1em;
}
.about-post img {
  width: 100%;
  height: 100%;
}
.about-post p {
  text-align: justify;
}

.contact-post {
  width: calc(45.8333333333% - 0.5em);
}

.page-navigation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 8em;
}
.single .page-navigation {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 1em;
  height: 2em;
}
.page-navigation .empty {
  color: #dadada;
}

.page-post {
  padding: 2em;
}
@media screen and (max-width: 39.99875em) {
  .page-post .wp-block-columns {
    flex-direction: column;
  }
}
@media screen and (max-width: 39.99875em) {
  .page-post .wp-block-column {
    width: 100%;
    margin-top: 2em;
  }
}
.page-post h1 {
  line-height: calc(1.25vw + 16px);
  font-size: 1.75em;
  margin-bottom: calc(1.25vw + 16px);
}
.page-post h2 {
  margin-top: calc(1.25vw + 16px);
  margin-bottom: calc(0.3125vw + 4px);
  line-height: calc(1.25vw + 16px);
  font-size: 1.5em;
}
.page-post h3 {
  margin-top: calc(0.625vw + 8px);
  margin-bottom: calc(0.3125vw + 4px);
  line-height: calc(0.9375vw + 12px);
  font-size: 1.25em;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

.post-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5em;
}

@keyframes loading-loop {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.single-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  width: 100%;
  height: calc(100% - 3em);
}
.single-container .post, .single-container img {
  width: 100%;
  height: 100%;
}

.post-container .post {
  transition: height 1s cubic-bezier(0.23, 1, 0.32, 1);
  width: 33.333%;
}
.post-container .post.loading {
  height: 22.692vw;
}
.post-container a {
  display: block;
  padding: 0.5em;
}

.post {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #dadada;
}
.post:after {
  transition: opacity 0.5s linear;
  overflow: hidden;
  opacity: 0;
  height: 0;
  content: "—";
}
.post.loading:after {
  opacity: 1;
  height: auto;
  transform-origin: center;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: loading-loop;
}
.post img {
  transition: opacity 1s linear;
  opacity: 0;
}
.post img.show {
  opacity: 1;
}

.wp-block-columns {
  display: flex;
  justify-content: space-between;
}

.wp-block-column {
  width: calc(50% - 1em);
}