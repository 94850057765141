.page-post{
  //@extend %em-grid; //グリッドテスト
  padding: 2em;
  
  .wp-block-columns{
    @include breakpoint(small down){
      flex-direction: column;
    }
  }
  .wp-block-column{
    @include breakpoint(small down){
      width: 100%;
      margin-top: 2em;
    }
  }
  .wp-block-column:first-of-type{
  }
  .wp-block-column:last-of-type{
    @extend %en-font;
    h2,h3{
      @extend %en-font-medium;
    }
  }

  h1{
    @extend %en-font-medium;
    line-height: font-size(base-double);
    font-size: 1.75em;
    margin-bottom: font-size(base-double);
  }
  h2{
    margin-top: font-size(base-double);
    margin-bottom: font-size(base-half);
    line-height: font-size(base-double);
    font-size: 1.5em;
  }
  h3{
    margin-top: font-size(base);
    margin-bottom: font-size(base-half);
    line-height: font-size(base-onehalf);
    font-size: 1.25em;
  }

}
